<script lang="ts">
    import { Accordion } from "bits-ui";
    import { ChevronDown } from "lucide-svelte";
    import { slide } from "svelte/transition";
   
    const items = [
      {
        title: "How can I sign up?",
        content:
          "You can sign up by clicking on the Sign Up button on the top right corner. Sign in with Google is currently the only method of creating an account."
      },
      {
        title: "What is the pricing?",
        content:
          "We are currently in the process of finalizing our pricing. Our pricing starts at $19.99/month, and will be offering a 7 day free trial period for all new users."
      },
      {
        title: "What if the Enterprise plan does not offer enough orders for me?",
        content:
          "You can contact us for a customized plan."
      },
      {
        title: "Is there a limit on the number of users?",
        content:
          "No.",
      },
      {
        title: "What technologies are you using?",
        content:
          "We are using Svelte, TailwindCSS and Python for our frontend/middleware. As for the automation, we are NOT using Selenium, Puppeteer, or any other browser automation tool. We also don't use any proxies.",
      },
      {
        title: "I can order faster than your bot. Why should I use this?",
        content:
          "You can order a single order faster, as the order takes around 45 second to complete. However, if you are ordering multiple items, our bot will be able to order them in parallel, 100 orders still takes 45 seconds to complete. We also collect all the order numbers and tracking numbers, and are availible in your dashboard.",
      },
      {
        title: "How can I contact you?",
        content:
          "Email: admin@tbcla.com  || Phone/WA 510-901-1516"
      }
    ];
  </script>
   
  <Accordion.Root class="w-4/5 border p-5 rounded-3xl border-black sm:w-2/2 sm:max-w-[70%]">
    {#each items as item, i}
      <Accordion.Item value="${i}" class="group border-b border-alttan-900 px-1.5">
        <Accordion.Header>
          <Accordion.Trigger
            class="flex w-full flex-1 text-start items-center justify-between py-5 text-[15px] font-playfair transition-all [&[data-state=open]>span>svg]:rotate-180 "
          >
            {item.title}

            <span
              class="inline-flex items-center justify-center rounded-[7px] bg-transparent transition-all sq-8 hover:bg-dark-10"
            >
              <ChevronDown class="transition-all duration-200 sq-[18px]" />
            </span>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content
          transition={slide}
          transitionConfig={{ duration: 200 }}
          class="pb-[25px] text-sm tracking-[-0.01em]"
        >
          {item.content}
          {#if i == 0}
          <a data-sveltekit-reload href="/login" class="text-xs text-alttan-900 hover:underline font-semibold">Click here to Sign up</a>
          {/if}
        </Accordion.Content>
      </Accordion.Item>
    {/each}
  </Accordion.Root>
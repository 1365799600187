<script>
// @ts-nocheck

  export let whybetter = ''
  import { cn } from "./utils";
  import { onMount } from "svelte";
  let progress = 0;
  function startOrder() {
    if (progress != 0) {
      progress = 0
    }
    const interval = setInterval(() => {
      progress += 0.1;
      if (Math.round(progress) >= 310.00) {
        //   progress = 0;
          clearInterval(interval);
      }
    }, 5);
    } 


</script>

{#if whybetter == "parallel"}
  
<div class="max-w-lg ">
<h2 class="font-playfair text-3xl my-3">Parallel Orders</h2>
<h2 class="font-playfair tracking-tight text-sm my-3">* Not to scale</h2>
<div class="flex flex-col justify-center">
<button on:click={startOrder} class="bg-black max-w-32 transition-colors duration-300 text-white hover:text-tan-600 font-playfair tracking-wide py-2 px-4 rounded-xl">
  {progress == 0 ? "Start Order":"Restart"}
</button>
<p class="font-playfair text-xl my-1">You submitting 3 orders</p>
<div class="flex flex-col gap-5">
    <div class="flex gap-3">
        <div class="w-1/3 bg-slate-500/90 rounded-full h-2.5 dark:bg-gray-700">
            <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 100 ? progress:"100"}%"></div>
        </div>
        <div class="w-1/3 bg-slate-500/90 rounded-full h-2.5 dark:bg-gray-700">
            <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 200 ? progress >= 100? progress-100:"0":"100"}%"></div>
        </div>
        <div class="w-1/3 bg-slate-500/90 rounded-full h-2.5 dark:bg-gray-700">
            <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 300 ? progress >= 200? progress-200:"0":"100"}%"></div>
        </div>
    </div>

    <p class="font-playfair text-xl my-1">Us submitting 3 orders</p>

    <div class="w-2/5 bg-slate-500/90 rounded-full h-2.5 dark:bg-gray-700">
        <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 110 ? progress*0.9:"100"}%"></div>
    </div>
    <div class="w-2/5 bg-slate-500/90 rounded-full h-2.5 dark:bg-gray-700">
        <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 110 ? progress*0.9:"100"}%"></div>
    </div>
    <div class="w-2/5 bg-slate-500/90 mb-6 rounded-full h-2.5 dark:bg-gray-700">
        <div class="bg-red-600  h-2.5 rounded-full" style="width: {progress <= 110 ? progress*0.9:"100"}%"></div>
    </div>
    
</div>
</div>

<p class="font-playfair text-md">Ordering with us takes between 45-60 seconds, depending on the order. Ordering 50 orders <span class="italic">still</span> takes between 45-60 seconds. The system collects the order number, so you don't have to manually find and record them</p>
</div>

{/if}

{#if whybetter == "multiple"}
<div class="max-w-lg ">
<h2 class="font-playfair text-3xl my-3">Multiple Accounts</h2>
<p class="font-playfair text-md">Managing multiple accounts is time consuming and error prone. Our system keeps track of all your accounts, usernames, passwords, order numbers and tracking numbers in one place. No more searching through all of your accounts trying to find the item that didn't arrive. In addition, all your logins are saved so when placing the next order you're still signed into your Amazon account. </p>
</div>
{/if}


{#if whybetter == "other"}
<div class="max-w-lg ">
<h2 class="font-playfair text-3xl my-3">Low Cost and Convenient
</h2>
<p class="font-playfair text-md">Our plans start from $19.99 a month. If your average order is $400 and you misplace it, or is undelivered and needs a refund, knowing the exact order and the account will save you from lost inventory. Our dashboard contains all your orders from all you accounts; so you never have to go searching again. All information is stored in the cloud, nothing is ever stored onto your computer.   </p>
</div>
{/if}









<script>
// @ts-nocheck

	import { apiData } from '$lib/store';
	import { ChevronDown, CheckCircle2, X, Check, Loader2, Truck, Box, MoveRight } from "lucide-svelte";
	import Rootaccordian from "./rootaccordian.svelte";
	import Pricecard from './pricecard.svelte';
	import Howbetter from './howbetter.svelte';
	let scrollY = 0;


	

</script>

<div class="flex flex-col justify-between bg-tan-500 text-tan-300 p-6 m-2 sm:m-6 h-[95vh] rounded-3xl  ">
	<div class="fixed flex flex-col font-playfair text-xl sm:text-4xl mix-blend-difference ">
		<div class="">THE BOT  COMPANY</div>
		 <div><span class="">of</span> LA </div>
	</div>

	<div class="flex flex-row justify-end text-blue-500 text-xs sm:text-sm ">
		<div class="flex sm:border-2 hover:border-white duration-200 text-black mix-blend-multip border-black p-1 px-1 sm:px-4 rounded-full">
			{#if $apiData.user}
			<a data-sveltekit-reload aria-label="Go to Orders" href="/orders" class="">Go to Orders</a>
			{:else}
			<a data-sveltekit-reload aria-label="Go login" href="/login" class="">Login / Signup</a>
			{/if}
		</div>
	</div>


	<div>
		<div class="text-center mt-16  text-3xl sm:text-5xl">
			<p class=" font-thin m-2 text-black">Go from checking emails to completely automated</p>
			<p class="font-playfair  text-black ">Ordering</p>
			<p class="font-serif  text-black ">&</p>
			<p class="font-playfair  text-black ">Tracking</p>
		</div>
		
		<div class="flex flex-col sm:flex-row justify-evenly m-2 p-2 pt-10 sm:pt-16 gap-4 items-center  px-3 py-4">
			
			<div class="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-gray-800  capitalize bg-yellow-300/50  duration-1000 ">
				<Loader2 class="animate-spin w-4 h-4" />
				queued
			</div>
			<MoveRight class="rotate-90 sm:rotate-0 text-black w-4 h-4" />
			
			<div class="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-gray-800  capitalize bg-blue-300/50  duration-1000 ">
				<Box class="w-4 h-4" />
				ordered
			</div>
			<MoveRight class="rotate-90 sm:rotate-0 text-black w-4 h-4" />

			
			<div class="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-gray-800  capitalize bg-teal-300/50  duration-1000 ">
				<Truck class=" w-4 h-4" />
				shipped
			</div>
			<MoveRight class="rotate-90 sm:rotate-0 text-black w-4 h-4" />

			
			<div class="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-gray-800  capitalize bg-emerald-400/50  duration-1000 ">
				<Check class=" w-4 h-4" />
				delivered
			</div>
			
		</div>
		
	</div>




	<div class=" flex flex-col items-center mx-auto m-1 p-1">
		<p class="text-center font-playfair text-black ">How are we better?</p>
		<ChevronDown class="text-black " size="64" />
	</div>



</div>


<!-- next slide -->
<div class="flex flex-col lg:flex-row">
	<div class=" bg-blue-200/90  hover:-translate-y-1 transition-all duration-200 m-2 sm:m-6 p-6 rounded-3xl"><Howbetter whybetter="parallel" /></div>
	<div class=" bg-red-200 m-2 sm:m-6 p-6 hover:-translate-y-1 transition-all duration-200 rounded-3xl"><Howbetter whybetter="multiple" /></div>
	<div class=" bg-teal-200 m-2 sm:m-6 p-6 hover:-translate-y-1 transition-all duration-200 rounded-3xl"><Howbetter whybetter="other" /></div>
</div>

<div class=" bg-black p-6 m-6 rounded-3xl">
	<h2 class="text-center text-3xl sm:text-5xl font-playfair text-tan-200">Pricing</h2>
	<h3 class="text-center text-md sm:text-xl m-4 font-playfair text-tan-400">All plans come with a 7 day free trial</h3>
	<div class="flex flex-col gap-2">
		<!-- <p class="text-tan-200"><CheckCircle2 class="inline-flex text-tan-200" /> hey</p> -->
		<!-- <p class="bg-blue-200">hey</p> -->
		<Pricecard />
	</div>
</div>


<!-- next slide FAQ -->


<div class="bg-gradient-to-br from-alttan-400 to-tan-500/80  transition-all duration-700   
{scrollY > 1300 ? 'rounded-none' : 'rounded-3xl p-6 m-2 sm:m-6'}
">
	<!-- <p>{scrollY}</p> -->
		<div class="flex flex-col py-36 items-center justify-center  h-1/2">
			<Rootaccordian />
			<!-- <Rootaccordian /> -->

		</div>

</div>

<svelte:window bind:scrollY={scrollY} />



<style>
	:global(body) {
		background-color: rgb(0, 0, 0);
		opacity: 1;
	}
</style>


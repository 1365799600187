

<script>
	// @ts-nocheck
	import { apiData } from '$lib/store';
	import Logo from '$lib/logo.svelte';
	import Orderdashboard from '$lib/orderdashboard.svelte';
	import Userdashboard from '$lib/userdashboard.svelte';

	/** @type {import('./$types').PageData} */
</script>

<title>tbcla</title>
<!-- add all relavent meta tags -->
<meta name="description" content="tbcla" />
<meta name="keywords" content="tbcla" />
<meta property="title" content="tbcla" />
<meta property="description" content="tbcla" />
<meta property="url" content="https://tbcla.com" />
<meta property="type" content="website" />




<body>

	<Logo />
	
</body>


  
  




